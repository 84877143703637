function updateHeaderBasket(price, qty) {
    $('.header__nav__item--basket, .header__basket').each(function() {
        $('.tag', $(this)).html(qty);
        $('.header__basket__price', $(this)).html(price);
    });
}

function disableCheckoutButton(disable) {
    $('.basket__checkout a').toggleClass('button--disabled', disable);
}

function checkMaxStock() {

    var disableButton = false;

    $('.basket__qty__input').each(function () {
        var input = $(this);
        var maximum = typeof input.data('max') !== 'undefined' ? input.data('max') : false;

        if (maximum !== false) {
            if (parseFloat(input.val()) > maximum) {
                input.closest( ".basket__item" ).find(".basket__detail__stock-warning").show()
                disableButton = true;
            } else {
                input.closest( ".basket__item" ).find(".basket__detail__stock-warning").hide()
            }
        }
    })

    if (disableButton === true) {
        disableCheckoutButton(true);
    } else {
        disableCheckoutButton(false);
    }
}

var basketChange;
var basketChangeTimeout;
var updateBufferTime = 2000;

$(document).on('change', '.basket__qty__input', function() {
    clearTimeout(basketChangeTimeout);
    basketChangeTimeout = setTimeout(function(){
        if (basketChange != null) {
            basketChange.abort();
        }
        basketChange = $.ajax('/basket/update', {
            type: 'POST',
            data: $('.basket__table input').serialize(),
            success: function(data) {
                if (data.result) {
                    $('.basket__wrapper').html(data.payload.table);
                }
                updateHeaderBasket(data.payload.price, data.payload.qty);
                checkMaxStock();
            }
        });
    }, 1000)
});

checkMaxStock();
