function getQueryParameters() {
    var queryString = location.search.slice(1),
        params = {};

    queryString.replace(/([^=]*)=([^&]*)&*/g, function (_, key, value) {
        params[key] = value;
    });

    return params;
}


function setQueryParameters(params) {
    var query = [],
        key, value;

    for (key in params) {
        if (!params.hasOwnProperty(key)) continue;
        value = params[key];
        query.push(key + "=" + value);
    }

    location.search = query.join("&");
}

function showAllColours(e) {
    e.preventDefault()
    let active = 'filterable__unslick--active'

    if (this.classList.contains(active)) {
        this.classList.remove(active);
        this.firstElementChild.textContent = 'show more colours'
    } else {
        this.classList.add(active)
        this.firstElementChild.textContent = 'show fewer colours'
    }
}

var showMoreButton = document.querySelector('.js-unslick');

if (showMoreButton) {
    showMoreButton.addEventListener('click', showAllColours)
}
