AndrewMartin.siteNoticeMessages = [];
AndrewMartin.siteNotice = () => {
    AndrewMartin.siteNoticeMessages = document.querySelectorAll('.js-header-message');

    function loopMessages(active)  {
        if (AndrewMartin.siteNoticeMessages.length) {
            AndrewMartin.siteNoticeMessages.forEach((m) => {
                m.classList.add('notice__content--hidden');
            })

            AndrewMartin.siteNoticeMessages[active].classList.remove('notice__content--hidden')

            if (++active >= AndrewMartin.siteNoticeMessages.length) {
                active = 0;
            }

            setTimeout(() => {
                loopMessages(active)
            }, 7000)
        }
    }

    if (AndrewMartin.siteNoticeMessages.length) {
        loopMessages(0);
    }
}
