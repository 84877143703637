/**
 * @author Joe Adcock <joe@d3r.com>
 */
$.fn.select2radio = function() {
    $(this).each(function() {
        var current = $(this);
        current.wrap('<div class="select2radio"></div>').hide();

        var wrap = $(this).parent();

        var ul = $('<ul>').appendTo(wrap);

        current.find('option').each(function() {
            if ($(this).val() == '') {
                return;
            }
            var option = $('<li>').data({
                val: $(this).val(),
                target: current
            }).addClass('select2radio__option').appendTo(ul);

            var span = $('<span>').html($(this).html()).appendTo(option);

            if (current.val() && current.val() == $(this).val()) {
                option.addClass('select2radio__option--active');
            }
        });
    });
};

$(document).on('click', '.select2radio__option', function() {
    $('.select2radio__option--active').removeClass('select2radio__option--active');
    $(this).addClass('select2radio__option--active');
    $(this).data().target.val($(this).data().val).trigger('change');
});
