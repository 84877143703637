var STATE = {
    params: null,
    enabled: true,

    disable: function() {
        this.enabled = false;
    },

    enable: function() {
        this.enabled = true;
    },

    getParams: function(force) {
        if (this.params == null || force) {
            var query = window.location.search.substring(1);
            this.params = AMURL.queryStringToParams(query);
        }

        return this.params;
    },

    getChanged: function() {
        var oldParams = this.params;
        this.getParams(true);
        var changed = [];

        if (oldParams) {
            for (var key in this.params) {
                if (this.params[key] != oldParams[key]) {
                    changed[key] = this.params[key];
                }
            }

            for (var key in oldParams) {
                if (this.params[key] != oldParams[key]) {
                    changed[key] = this.params[key];
                }
            }
        } else {
            changed = this.params;
        }

        return changed;
    },

    setParam: function(key, val) {
        this.getParams();

        if (val == null) {
            delete this.params[key];
        } else {
            this.params[key] = val;
        }

        return this;
    },

    setState: function(state) {
        var pair = state.split('=');
        var key = pair[0];
        var val = pair[1];

        this.setParam(key, val);

        return this;
    },

    setHash: function(hash) {
        if (hash.indexOf('#') == -1) {
            hash = '#' + hash;
        }

        var url = window.location.protocol + '//' + window.location.hostname + window.location.pathname + window.location.search + hash;

        if (window.location != url) {
            history.pushState({}, null, url);
        }
    },

    getUrl: function() {
        var url = AMURL.parts(window.location);
        this.getParams();
        url.querystring = AMURL.paramsToQueryString(this.params);

        return url.path + url.querystring + url.hash;
    },

    pushUrl: function(url) {
        if (this.enabled) {
            if (url == undefined) {
                url = this.getUrl();
            }

            if (window.location != url) {
                history.pushState({}, null, url);
            }
        }

        return this;
    },

    replaceUrl: function(url) {
        if (this.enabled) {
            if (url == undefined) {
                url = this.getUrl();
            }

            history.replaceState({}, null, url);
        }

        return this;
    },

    getStorage: function($option, name) {
        if ($option.hasClass('js-store_state')) {
            return localStorage[name];
        } else {
            return sessionStorage[name];
        }
    },

    save: function($option) {
        if (!this.enabled) {
            return;
        }

        var name  = undefined;
        var value = undefined;


        if ($option.data('state')) {
            var s = $option.data('state').split('=');
            name  = s[0];
            value = s[1];

            if ($option.hasClass('js-toggle_active')) {
                var activate_sel = $option.data('activate');
                var $activate    = activate_sel ? $(activate_sel) : $option;

                if (!$activate.hasClass('js-is_active')) {
                    value = undefined;
                }
            }
        } else {
            name  = $option.prop('name');
            value = $option.val();

            if ($option.prop('tagName') == 'INPUT') {
                var type  = $option.prop('type');

                if ((type == 'checkbox' || type == 'radio') && $option.not(':checked')) {
                    value = undefined;
                }
            }
        }

        if ($option.hasClass('js-history_state')) {
            STATE.setParam(name, value);
            STATE.pushUrl();
        }

        if ($option.hasClass('js-store_state')) {
            localStorage.setItem(name, value);
        }

        if ($option.hasClass('js-session_state')) {
            sessionStorage.setItem(name, value);
        }
    },

    load: function(scope) {
        var $scope = $(scope);

        this.disable();

        $scope.find('a.js-store_state, a.js-session_state, button.js-store_state, button.js-session_state').each(function() {
            var $option = $(this);
            var s = $option.data('state').split('=');
            name  = s[0];
            value = s[1];
            var stored  = STATE.getStorage($option, name);

            if (value == stored) {
                $option.trigger('click');
            }
        });

        $scope.find('input.js-store_state, input.js-session_state').each(function() {
            var $option = $(this);
            var name    = $option.prop('name');
            var type    = $option.prop('type');
            var value   = $option.val();
            var stored  = STATE.getStorage($option, name);

            if (stored != undefined) {
                if (type == 'checkbox' || type == 'radio') {
                    if (value == stored) {
                        $option.trigger('click');
                    }
                } else {
                    $option.val(stored).trigger('change');
                }
            }
        });

        $scope.find('select.js-store_state, select.js-session_state').each(function() {
            var $option = $(this);
            var name    = $option.prop('name');
            var stored  = STATE.getStorage($option, name);

            if (stored != undefined) {
                $option.val(stored).trigger('change');
            }
        });

        var changed = this.getChanged();

        for (var key in changed) {
            var val = changed[key];
            if (val == null || val == undefined) {
                val = '';
            }
            var kav = key + '=' + val;
            var triggered = false;

            $scope.find('a.js-history_state[data-state="' + kav + '"]:first').each(function() {
                triggered  = true;
                $(this).trigger('click');
            });

            if (triggered) {
                continue;
            }

            if (val == '') {
                // no matches for no value, so maybe it's just the default option like house fabric
                $scope.find('a.js-history_state[data-state^="' + kav + '"]:first').each(function() {
                    triggered  = true;
                    $(this).trigger('click');
                });

                if (triggered) {
                    continue;
                }
            }

            $scope.find('input.js-history_state[name="' + key + '"][value="' + val + '"]').each(function() {
                triggered  = true;
                $(this).trigger('click');
            });

            if (triggered) {
                continue;
            }

            $scope.find('select.js-history_state[name="' + key + '"]:first').each(function() {
                triggered  = true;
                $(this).val(val).trigger('change');
            });
        }

        this.enable();
    },

    loadHandlers: function(scope) {
        var $scope = $(scope);

        $scope.find('.js-history_state, .js-store_state, .js-session_state').each(function() {
            var option  = this;
            var $option = $(this);

            if (option.tagName == 'A' || option.tagName == 'BUTTON') {
                $option.on('click _update', function(e) {
                    e.preventDefault();
                    STATE.save($option);
                });
            }

            if (option.tagName == 'INPUT') {
                $option.on('change _update', function(e) {
                    STATE.save($option);
                });
            }

            if (option.tagName == 'SELECT') {
                $option.on('change _update', function(e, initial) {
                    if (initial) {
                        // change is called by selectReplace on page load - not a real 'change' so ignore
                        return;
                    }
                    STATE.save($option);
                });
            }
        });

        STATE.load(scope);
    }
};

$(function () {
    // eg $('#simplemodal-container').trigger('_loadHandlers');
    $(document).on('_loadHandlers', function(e) {
        STATE.loadHandlers(e.target);
    });

    STATE.loadHandlers(document);
});
