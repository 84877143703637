var newsletter;
$(document).on('submit', '.signup-form', function(e) {
    e.preventDefault();

    var form = this;

    if (newsletter != null) {
        newsletter.abort();
    }
    newsletter = $.ajax($(this).attr('action'), {
        type: $(this).attr('method'),
        data: $(this).serialize(),
        success: function(data) {
            if (data.result) {
                $(form).fadeTo(400, 0, function() {
                    $(form).parent().append('<label class="signup-form__thanks">Thank you</label>');
                });
            }
        }
    });
});
