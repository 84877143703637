AndrewMartin.photoswipe = () => {
    var pswpElement = document.querySelectorAll('.pswp')[0];

    $(document).on('click', '[data-photoswipe]', function(e) {
        var link = $(this),
            galleryKey = link.data('photoswipe'),
            imageId = link.data('photoswipe-id'),
            images = window[galleryKey],
            options = {},
            imageIndex, gallery;

        e.preventDefault();

        if (!images) {
            throw 'No images could be found for key ' + galleryKey;
        }

        $.each(images, function(i, v) {
            if (parseInt(v.id, 10) === parseInt(imageId, 10)) {
                imageIndex = i;
                return false;
            }
        });

        if (typeof imageIndex !== 'undefined') {
            options.index = imageIndex;
        }

        gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, images, options);
        gallery.init(options);
    });
}
