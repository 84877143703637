// $('.js-kit-price input').on('change', function() {
//     var input = $(this);
//     var sku = input.data('frame-sku');

//     var form = $('#basket-add');
//     form.find('button.product__button').prop('disabled', 'disabled');

//     $.ajax('/products/kit-price', {
//         type: 'post',
//         data: $('.product-piece input').serialize(),
//         success: function(data) {
//             form.find("input." + sku).val(input.val());

//             if (typeof(fdPrice) != 'undefined') {
//                 fdPrice = parseFloat(data.payload.price_raw).toFixed(2);
//                 updateFinancing();
//                 initPopups();
//             }

//             $('.product__total-price span.value').html(data.payload.price);

//             if (data.payload.price_raw > 0) {
//                 form.find('button.product__button').prop('disabled', false).removeClass('button--disabled');
//                 $('.product__total-price').show();
//                 $('.product__finance-option').show();
//             } else {
//                 $('.product__total-price').hide();
//                 $('.product__finance-option').hide();
//             }
//         }
//     });
// });
