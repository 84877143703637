var initProductGallery = function() {
    var gallery = $('.product__gallery .product__photo');
    var videoContainer = $('.product__gallery .product__video');
    var video = $('.product__gallery .product__video video');
    var thumbnail = $('.product__thumbnail');
    var className = 'product__thumbnail--active';

    if (!gallery.length) {
        return;
    }

    if (video.length > 0) {
        var player = videojs(video[0].id);
    }

    function update(i) {
        thumbnail.eq(i).addClass(className);
    }

    function watchVideo() {
        if (video.length > 0) {
            player.aspectRatio('1:1');
            // Not using the `video` variable as slick removes the DocElement and re-adds so breaks this listener
            gallery.on('touchend click', '.product__video video', function(e) {
                if (player.paused()) {
                    playVideo();
                } else {
                    pauseVideo()
                }
            });
        }
    }

    function playVideo() {
        player.play();
        videoContainer.addClass('product__video--playing');
    }

    function pauseVideo() {
        player.pause();
        videoContainer.removeClass('product__video--playing');
    }

    gallery.on('init', function() {
        update(0);
        watchVideo();

        if (window.matchMedia('(min-width: 999px)').matches) {
            $('.zoom--pdp').zoom({
                canvas: '.zoom__canvas--pdp',
                trigger: '.zoom__trigger--pdp',
                backdrop: '.zoom__backdrop--pdp',
            });
        }
    });
    gallery.on('afterChange', function() {
        $('.' + className).removeClass(className);
        update($(this).slick('slickCurrentSlide'));
        if (player) {
            if (!player.paused()) {
                pauseVideo();
            }
        }
    });
    thumbnail.on('click', function() {
        gallery.slick('slickGoTo', $(this).index());
    });

    gallery.slick({
        nextArrow: '<button class="slick-arrow slick-next">' +
            '<svg class="icon-expand">' +
                '<use xlink:href="' + AndrewMartin.svgSymbolsFile + '#icon-expand" />' +
            '</svg>' +
            '</button>',
        prevArrow: '<button class="slick-arrow slick-prev">' +
            '<svg class="icon-collapse">' +
                '<use xlink:href="' + AndrewMartin.svgSymbolsFile + '#icon-collapse" />' +
            '</svg>' +
            '</button>',
        dots: true
    });

    $('button', gallery).click(function(e) {
        e.preventDefault();
    });
};

initProductGallery();

var initGalery = function() {
    var gallery = $('.carousel');
    gallery.each(function() {
        var el = $(this);
        var infinite = el.data('noloop') == undefined;
        var img = $('.carousel__item', el);

        el.on('init', function(e, slick) {
            updateArrows(slick.currentSlide + 1, slick.slideCount);
        });

        el.on('afterChange', function(e, slick) {
            updateArrows(slick.currentSlide + 1, slick.slideCount);
        });

        el.slick({
            nextArrow: '<button class="slick-arrow slick-next">' +
            '<svg class="icon-expand">' +
            '<use xlink:href="' + AndrewMartin.svgSymbolsFile + '#icon-expand" />' +
            '</svg>' +
            '</button>',
            prevArrow: '<button class="slick-arrow slick-prev">' +
            '<svg class="icon-collapse">' +
            '<use xlink:href="' + AndrewMartin.svgSymbolsFile + '#icon-collapse" />' +
            '</svg>' +
            '</button>',
            dots: true,
            infinite: infinite
        });

        function updateArrows(current, total) {
            if (current == 1) {
                el.addClass('carousel--at-start');
            } else {
                el.removeClass('carousel--at-start');
            }

            if (current == total) {
                el.addClass('carousel--at-end');
            } else {
                el.removeClass('carousel--at-end');
            }
        }
    });
};

initGalery();

$(function () {
    if (window.CYLINDO !== undefined) {
        const popupScroll = document.querySelector('.js-cylindo-popup');

        if (window.matchMedia('(max-width: 999px)').matches) {
            CYLINDO.instantiate('js-cylindo_mobile', 'mobile-popup', window.cylindoGallery);
        } else {
            CYLINDO.instantiate('js-cylindo_viewer', 'gallery', window.cylindoGallery);
        }

        $(document).on('click', '.js-cylindo-open', function(e) {
            e.preventDefault();

            var $button = $(this);
            var $popup   = $($button.data('popup'));
            var $cylindo = $($button.data('cylindo'));

            $cylindo.appendTo($popup);

            $popup.appendTo('body').fadeIn('fast');

            if (window.matchMedia('(max-width: 999px)').matches) {
                scrollLock.disablePageScroll(popupScroll);
            }
        });

        $(document).on('click', '.js-cylindo-close', function(e) {
            e.preventDefault();

            // Add unzoom functionality to the back button for mobile

            if (document.querySelector('html').classList.contains('cylindo-zoom')) {
                var cylindo = $(this).data('cylindo-mobile');

                CYLINDO.zoom(cylindo, false);
            } else {
                var $button = $(this);
                var $popup = $button.closest('.js-cylindo-popup');
                $popup.fadeOut('fast');

                scrollLock.enablePageScroll(popupScroll);
            }
        });

        $(document).on('click', '.js-cylindo-zoom', function(e) {
            e.preventDefault();

            var cylindo;

            if (window.matchMedia('(max-width: 999px)').matches) {
                cylindo = $(this).data('cylindo-mobile');
            } else {
                cylindo = $(this).data('cylindo');
            }

            CYLINDO.zoom(cylindo, true);
        });

        $(document).on('click', '.js-cylindo-unzoom', function(e) {
            e.preventDefault();

            var cylindo;

            if (window.matchMedia('(max-width: 999px)').matches) {
                cylindo = $(this).data('cylindo-mobile');
            } else {
                cylindo = $(this).data('cylindo');
            }

            CYLINDO.zoom(cylindo, false);
        });
    }
});
