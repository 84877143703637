/**
 * I would do this in CSS but we need to add in the SVG
 * symbol anyway and we only want to show the custom
 * checkbox if this code is run so better to do it here
 *
 * @author Neil Brayfield <neil@d3r.com>
 */
$.fn.checkReplace = function() {
    $(this).each(function() {
        var current = $(this);
        current.wrap('<div class="check-replace"></div>').hide();

        var wrap = $(this).parent();

        wrap.append(
            '<svg class="icon-tick">' +
                '<use xlink:href="' + AndrewMartin.svgSymbolsFile + '#icon-tick" />' +
            '</svg>'
        );

        current.change(function (e) {

            if (current.prop('checked')) {
                wrap.addClass('check-replace--active');
            } else {
                wrap.removeClass('check-replace--active');
            }

            return true;
        });

        $(this).trigger('change');
    });
};
