$('.js-grid-load-more').click(function(e) {
    e.preventDefault();

    var grid = $(this).siblings('.wrap').find('.grid');
    var current = $(this).addClass('load-more--loading');

    $.ajax($(this).attr('href'), {
        success: function(data) {
            grid.children(':hidden').children('.product-listing').addClass('product-listing--to-load');

            grid.children().removeClass('s-hidden-true m-hidden-true ph-hidden-true ml-hidden-true l-hidden-true');
            grid.append(data.payload.html);

            url = new URL(window.location.href);

            load_more = url.searchParams.get('load_more');
            if (!load_more) {
                load_more = 1;
            }
            load_more++;
            url.searchParams.set('load_more', load_more);

            window.history.pushState({load_more: load_more}, document.title, url.toString());

            if (data.payload.page && data.payload.more) {
                current.attr('href', data.payload.more).removeClass('load-more--loading');
            } else {
                current.remove();
            }
            setTimeout(function(){
                $('.product-listing--to-load').removeClass('product-listing--to-load');
            }, 100);
        }
    });
});
