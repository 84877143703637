AndrewMartin.bloomreach = () => {
    $('.js-bloomreach-identify').on('submit', function() {
        if (typeof exponea === 'undefined') {
            console.error('Bloomreach not initialised');
            return true;
        }

        const email = $(this).find('input[name$="email"]').val() || $(this).find('input[name$="email]"]').val();

        if (!email) {
            console.error('No email address to send to bloomreach');
            return true;
        }

        exponea.identify(
            {
              'email_id': email
            },
            {
              'email': email
            }
        );

        return true;
    });
}
