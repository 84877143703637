var AMURL = {
    build: function(parts) {
        var url = parts.path;

        parts.querystring = parts.querystring.replace('?', '');
        if (parts.querystring !== '') {
            url += '?' + parts.querystring;
        }

        parts.hash = parts.hash.replace('#', '');
        if (parts.hash !== '') {
            url += '#' + parts.hash;
        }

        return url;
    },

    parts: function(url) {
        var parts = url.toString().split('#');
        url = parts[0];
        var hash = parts.length > 1 ? '#' + parts[1] : '';

        parts = url.split('?');
        var path = parts[0];
        var qs   = parts.length > 1 ? parts[1] : '';

        return {'path': path, 'querystring': qs, 'hash': hash};
    },

    paramsToQueryString: function(params) {
        var pairs = [];
        for (var key in params) {
            var val = params[key];

            if (key != '' && val != null && val != undefined && val != '') {
                pairs.push(key + '=' + params[key]);
            }
        }

        if (pairs.length > 0) {
            return '?' + pairs.join('&');
        } else {
            return '';
        }
    },


    queryStringToParams: function(queryString) {
        if (queryString.indexOf('?') > -1) {
            var queryString = queryString.split('?')[1];
        }

        var pairs = queryString.split('&');

        params = [];
        for (var i=0; i<pairs.length; i++) {
            var pair = pairs[i].split('=');
            params[pair[0]] = pair[1];
        }

        return params;
    },

    setQueryStringParam: function(queryString, key, value) {
        var params = this.queryStringToParams(queryString);
        params[key] = value;
        return this.paramsToQueryString(params);
    }
};
