var sliders = {};
var sliding = false;
var filterUrl = null;

function updateAjax(url) {
    $.ajax(url, {
        success: function(data) {
            window.history.pushState(null, null, url);
            filterUrl = url;
            var grid = $('#products_container');
            grid.find('.product-listing').addClass('product-listing--to-load');

            setTimeout(function(){
                grid.replaceWith(data.payload.html);
                if (typeof AndrewMartin.listingItem == 'undefined') {
                    AndrewMartin.listingItem = new ListingItem();
                }
                AndrewMartin.listingItem.init();

                $('#product_fromitemnum').text(data.payload.fromItemNum);
                $('#product_toitemnum').text(data.payload.toItemNum);
                $('.product_count').text(data.payload.productCount);
                if ($('.filterable').hasClass('filterable--active')) {
                    $('[hide-from-filters]').addClass('hide-from-filters');
                }
                $('.product-listing--to-load').removeClass('product-listing--to-load');
                handlePaintLinks();
            }, 300);
        }
    });
}

window.onpopstate = function(event) {
    if (filterUrl && filterUrl !== document.location.pathname + document.location.search) {
        document.location.reload();
    }
};

checkFilterCount();

$(document).on('change', '.filterable :input:not(.filterable--ignore)', function () {

    if (this.getAttribute('data-validation')) {
        // If value is not a number, set it to the minimum value
        if (isNaN(parseInt(this.value))) {
            if (this.hasAttribute('min')) {
                this.value = this.min;
            } else {
                this.value = 0;
            }
        }

        // If value is lower than the minimum value, set it to the minimum value
        if (this.hasAttribute('min')) {
            if (parseInt(this.value) < parseInt(this.min)) {
                this.value = this.min;
            }
        }

        // If value is higher than the maximum value, set it to the maximum value
        if (this.hasAttribute('max')) {
            if (parseInt(this.value) > parseInt(this.max)) {
                this.value = this.max;
            }
        }
    }

    if ($(this).parents('.js-filter-ranges').length > 0) {
        new updateFilterRanges($(this).parents('.js-filter-ranges'));
    }

    var url = '';
    var form = $('#filters_form');

    $('.filterable .filterable__dimension :input:not(.filterable--ignore), .filterable .filterable__option :input:not(.filterable--ignore)').each(function() {
        var key = this.name;
        var value = $(this).val();
        var tag = $(this).prop('tagName');
        var type = $(this).prop('type');
        var valid = false;

        if (key && value) {
            if (tag == 'SELECT') {
                valid = true;
            } else if (tag == 'INPUT') {
                if (type == 'radio' || type == 'checkbox') {
                    if ($(this).is(':checked')) {
                        valid = true;
                    }
                } else {
                    valid = true;
                }
            }

            if (valid) {
                if (Array.isArray(value)) {
                    for (var i = 0; i < value.length; i++) {
                        (function(i) {
                            if (url == '') {
                                url += '?';
                            } else {
                                url += '&';
                            }
                            url += key + '[]=' + encodeURIComponent(value[i]);
                        })(i);
                    }
                } else {
                    if (rangeIsDefault($(this))) {
                        return;
                    }
                    if (url == '') {
                        url += '?';
                    } else {
                        url += '&';
                    }
                    url += key + '=' + encodeURIComponent(value);
                }
            }
        }
    });

    checkFilterCount();

    url = window.location.pathname + url;

    $('#products_container').addClass('product-listing--loading');

    // If a related slider exists, then update it
    if (this.getAttribute('data-field')) {
        var field = this.getAttribute('data-field');//.split('---');
        var slider = sliders[field];

        if (slider) {
            if (!sliding) {
                var values = this.getAttribute('data-value') == 'min' ? [this.value, null] : [null, this.value];
                slider.noUiSlider.set(values);
            }
            sliding = false;
        }
    }

    clearTimeout(form.data('timer'));
    form.data('timer', setTimeout(function() {
        updateAjax(url);
    }, 750));
});

$('.js-filter-ranges').each(function() {
    new updateFilterRanges($(this));
});

function updateFilterRanges(el) {
    this.selected = {};
    this.allRanges = {};
    this.el = el;

    var self = this;

    this.updateFilterRangesOverview = function() {
       this.el.find(':input:not(.filterable--ignore)').each(function() {
            var value = $(this).attr('data-value');
            var column = $(this).attr('data-column');
            if (!rangeIsDefault($(this))) {
                if (!self.selected[column]) {
                    self.selected[column] = {};
                }
                self.selected[column][value] = $(this).val();
            }
            if (!self.allRanges[column]) {
                self.allRanges[column] = {};
            }
            self.allRanges[column][value] = $(this).val();
        });

        if (Object.keys(this.selected).length > 0) {
            this.el.addClass('filter-ranges--selected');
        } else {
            this.el.removeClass('filter-ranges--selected');
        }

        var label = Object.keys(this.selected).length > 0 ? Object.keys(this.selected).length + ' selected' : 'Select&hellip;';

        this.el.find('.js-filter-ranges__overview').html(label);
    }

    this.updateFilterRangesSelection = function() {
        var keys = Object.keys(this.selected);
        var label = '';

        for (var i = 0; i < keys.length; i++) {
            (function(i) {
                var values = self.allRanges[keys[i]];
                label += self.capitalize(keys[i]) + ': ' + values.min + '-' + values.max + 'cm';

                if (i < keys.length - 1) {
                    label += ', ';
                }
            })(i);
        }

        this.el.find('.js-filter-ranges__selection').html(label);
        this.el.find('.js-filter-ranges__selection-mobile').html(keys.length);
        this.el.find('.js-filterable__count').html(keys.length ? '(' + keys.length + ')' : '');

        if (keys.length) {
            this.el.parents('.filterable__dimension').addClass('filterable__dimension--has-selection');
        } else {
            this.el.parents('.filterable__dimension').removeClass('filterable__dimension--has-selection');
        }
    }

    this.capitalize = function(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    this.updateFilterRangesOverview();
    this.updateFilterRangesSelection();

    $(document).on('click', '.js-filter-ranges__clear', function(e) {
        sliding = true;

        self.el.find('.js-filter-range__slider').each(function() {
            var range = this.noUiSlider.options.range;
            this.noUiSlider.set([range.min, range.max]);
        });
    });
}

// Toggle visibility of the clicked filter range
$(document).on('click', '.js-filter-ranges__top', function(e) {
    var el = $(this).closest('.js-filter-ranges');
    if (el.hasClass('filter-ranges--active')) {
        deactivateFilterRanges(el);
    } else {
        activateFilterRanges(el);
    }
});

$(document).on('click', '.js-filter-ranges__back', function(e) {
    var el = $(this).closest('.js-filter-ranges');
    deactivateFilterRanges(el);
});

$(document).on('click', '.js-filter-ranges label', function(e) {
    var el = $(this).closest('.js-filter-ranges').find('.js-filter-ranges__top');
    el.trigger('click');
});

// Deactivate any active filter ranges when document is clicked
$(document).on('click', function(e) {
    var el = e.target.closest('.js-filter-ranges');

    if (!el && $('.filter-ranges--active').length) {
        $('.js-filter-ranges').removeClass('filter-ranges--active');
    }
});

function rangeIsDefault(el) {
    return (el.attr('data-value') == 'min' && el.val() == el.attr('min')) || (el.attr('data-value') == 'max' && el.val() == el.attr('max'))
}

$(document).on('click', '.js-filterable-toggle', function(e) {
    e.preventDefault();
    $('.filterable').toggleClass('filterable--active');
    $('#filters_form').toggleClass('filterable--absolute');
    $('.footer').toggleClass('hide-from-filters');
    if ($('.filterable').hasClass('filterable--active')) {
        $('[hide-from-filters]').addClass('hide-from-filters');
    } else {
        $('[hide-from-filters]').removeClass('hide-from-filters');
        deactivateFilterRanges($('.js-filter-ranges'));
    }
});

$(document).on('click', function(e) {

    if (!(e.target.closest('.js-filter-ranges'))) {
        deactivateFilterRanges($('.js-filter-ranges'))
    }
})

function activateFilterRanges(el) {
    el.addClass('filter-ranges--active');
    el.parents('.filterable__dimension').addClass('filterable__dimension--active');
    el.parents('.filterable__content').addClass('filterable__content--active');
}

function deactivateFilterRanges(el) {
    el.removeClass('filter-ranges--active');
    el.parents('.filterable__dimension').removeClass('filterable__dimension--active');
    el.parents('.filterable__content').removeClass('filterable__content--active');
}

// Check the number of filters that have been set
function checkFilterCount() {
    var filterCount = [];

    $('.filterable .filterable__dimension :input:not(.filterable--ignore)').each(function() {
        var key = this.name;
        var value = $(this).val();
        if (key && value) {
            if (Array.isArray(value)) {
                for (var i = 0; i < value.length; i++) {
                    (function(i) {
                        var filterType = key;

                        if (filterCount.indexOf(filterType) == -1) {
                            filterCount.push(filterType);
                        }
                    })(i);
                }
            } else {
                if (rangeIsDefault($(this))) {
                    return;
                }

                var filterType = $(this).attr('data-dimension');

                if (filterCount.indexOf(filterType) == -1) {
                    filterCount.push(filterType);
                }
            }
        }
    });

    if (filterCount.length > 0) {
        $('.filterable').addClass('filterable--has-filter-selected');
    } else {
        $('.filterable').removeClass('filterable--has-filter-selected');
    }

    $('.js-filterable__total-count').html(filterCount.length);
}

$(function() {
    var windowResizing;
    var mode;
    var oldMode;

    function moveElement() {
        mode = window.matchMedia('(max-width: 580px)').matches ? 'mobile' : 'desktop';

        if (mode != oldMode) {
            var from = 'desktop';
            var to = 'mobile';

            if (mode == 'desktop') {
                from = 'mobile';
                to = 'desktop';
            }

            $('.js-moveable-content[data-visible="' + from + '"]').children().eq(0).appendTo($('.js-moveable-content[data-visible="' + to + '"]'));
        }

        oldMode = mode;
    }

    moveElement();

    $(window).on('resize', function() {
        clearTimeout(windowResizing);
        windowResizing = setTimeout(function() {
            moveElement();
        }, 100);
    });
});

$(function() {
    var slider = document.querySelectorAll('.js-filter-range__slider');
    var setBoth = document.querySelectorAll('.js-filter-range__set-both');
    var input = document.querySelectorAll('.js-filter-range__input');
    var allowed = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57] // 0-9
    var range = {};

    for (var i = 0; i < input.length; i++) {
        (function(i) {
            input[i].setAttribute('data-validation', 'number');
            input[i].type = 'text';

            input[i].onfocus = function() {
                this.onkeypress = function(e) {
                    // If the key is not in the array of allowed keys, then prevent it
                    if (allowed.indexOf(e.charCode) == -1) {
                        e.preventDefault();
                    }
                }
            }

            var dimension = input[i].getAttribute('data-dimension');
            var column = input[i].getAttribute('data-column');
            var min = input[i].getAttribute('min');
            var max = input[i].getAttribute('max');

            range[dimension + '-' + column] = {
                'min': parseInt(min),
                'max': parseInt(max)
            };
        })(i);
    }

    for (var i = 0; i < setBoth.length; i++) {
        (function(i) {
            var field = setBoth[i].getAttribute('data-field');
            var minInput = document.querySelector('.js-filter-range__input[data-value="min"][data-field="' + field + '"]');
            var maxInput = document.querySelector('.js-filter-range__input[data-value="max"][data-field="' + field + '"]');

            setBoth[i].onchange = function() {
                var range = this.value.split('-');
                minInput.value = range[0];
                maxInput.value = range[1];

                $(minInput).change();
                $(maxInput).change();
            }
        })(i);
    }

    for (var i = 0; i < slider.length; i++) {
        (function(i) {
            var field = slider[i].getAttribute('data-field');
            var minInput = document.querySelector('input[data-value="min"][data-field="' + field + '"]');
            var maxInput = document.querySelector('input[data-value="max"][data-field="' + field + '"]');
            var params = {
                start: [
                    minInput.value,
                    maxInput.value
                ],
                range: range[field],
                format: wNumb({
                    decimals: 0
                }),
                step: 1,
                connect: true
            }

            noUiSlider.create(slider[i], params);

            slider[i].noUiSlider.on('update', function(values, handle) {
                var value = handle == 1 ? 'max' : 'min';
                var input = document.querySelector('input[data-value="' + value + '"][data-field="' + field + '"]');

                input.value = values[handle];

                if (sliding) {
                    $(input).change();
                }
            });

            slider[i].noUiSlider.on('slide', function(values, handle) {
                sliding = true;
            });

            sliders[slider[i].getAttribute('data-field')] = slider[i];
        })(i);
    }
})
