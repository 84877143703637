$('.product__sizes li.sizes__option').click(function() {
    var selection = $(this);

    // don't do anything if the size is already selected
    if (selection.hasClass('sizes__option--active') && !selection.hasClass('sizes__option--active--first')) {
        return;
    }

    $.ajax({
        url: selection.data('url'),
        type: 'GET',
        success: function(data) {
            if (data.payload.specification) {
                $.each(data.payload.specification, function(index, value) {
                    $('#spec_key--'+index).find('.spec_value').text(value.value)
                });
            }

            var html = '';
            if (data.payload.offer.has_offer) {
                html = '<span class="product__price--was">was '+data.payload.price+'</span> '
                    + '<span class="product__price--discount">now '+data.payload.offer.price+'</span>'
                    + '<meta itemprop="price" content="'+data.payload.offer.price_raw+'">'
                    + '<meta itemprop="priceCurrency" content="'+data.payload.currency+'">';
            } else {
                html = data.payload.price
                    + '<meta itemprop="price" content="'+data.payload.price_raw+'">'
                    + '<meta itemprop="priceCurrency" content="'+data.payload.currency+'">';
            }

            $('.product__price').html(html);
            $('.product__form--addbasket').attr('action', data.payload.basket_url);
            $('.product__availability__status').text(data.payload.stock_message.status);
            $('.product__availability__information').text('- '+data.payload.stock_message.message);

            // Update the financing calculations
            if (typeof(fdPrice) != "undefined") {
                fdPrice = parseFloat(data.payload.price_raw).toFixed(2);
                updateFinancing();
            }
        }
    });

    $('.product__sizes .sizes__option').removeClass('sizes__option--active');
    selection.addClass('sizes__option--active');
    selection.removeClass('sizes__option--active--first');
});

$('.product__sizes .d3r-size-select').change(function() {
    var select = $(this);

    if (!select.find(':selected').data('url')) {
        return;
    }

    $.ajax({
        url: select.find(':selected').data('url'),
        type: 'GET',
        success: function(data) {
            if (data.payload.specification) {
                $.each(data.payload.specification, function(index, value) {
                    $('#spec_key--'+index).find('.spec_value').text(value.value)
                });
            }

            var html = '';
            if (data.payload.offer.has_offer) {
                html = '<span class="product__price--was">was '+data.payload.price+'</span> '
                    + '<span class="product__price--discount">now '+data.payload.offer.price+'</span>'
                    + '<meta itemprop="price" content="'+data.payload.offer.price_raw+'">'
                    + '<meta itemprop="priceCurrency" content="'+data.payload.currency+'">';
            } else {
                html = data.payload.price
                    + '<meta itemprop="price" content="'+data.payload.price_raw+'">'
                    + '<meta itemprop="priceCurrency" content="'+data.payload.currency+'">';
            }

            $('.product__price').html(html);
            $('.product__form--addbasket').attr('action', data.payload.basket_url);
            $('.product__availability__status').text(data.payload.stock_message.status);
            $('.product__availability__information').text('- '+data.payload.stock_message.message);
            $('.product__delivery-information__text').html(data.payload.delivery_information);

            // Update the financing calculations
            if (typeof(fdPrice) != "undefined") {
                fdPrice = parseFloat(data.payload.price_raw).toFixed(2);
                updateFinancing();
            }
        }
    });
});

$('.colourways__item').on('mouseover', function(){
    $('.colourways__text').html('<span>Colour: </span>' + $(this).data('colour'));
});

$('.colourways__item').on('mouseout', function(){
    $('.colourways__text').html('<span>Colour: </span>' + $('.colourways__text').data('colour'));
});

function updateFinancing() {
    if ($('.product__financing').length == 0 || typeof(fdOptions) == "undefined") {
        return;
    }

    var $finTable  = $('#finance-calculator table tbody'),
        price      = parseFloat(fdPrice),
        tdDetails  = [];

    if (price < fdMinPrice) {
        $('.product__financing').hide();
        return;
    }
    $('.product__financing').show();

    $('select', $finTable).empty();

    var i = 0;
    for (var fdOpt in fdOptions) {
        var code        = fdOptions[fdOpt]['code'],
            depositPerc = fdOptions[fdOpt]['deposit'],
            depositVal  = depositPerc / 100,
            depositAmt  = price * depositVal;

        var opt = new FinanceDetails(code, price, depositPerc, depositAmt);
        if (opt) {
            tdDetails.push(opt);

            // Only show the first option on the PDP directly
            if (i == 0) {
                $('.product__financing--message').html(
                    '&pound;' + parseFloat(opt.m_inst).toFixed(2) +
                    ' per month with a &pound;' + parseFloat(opt.d_amount).toFixed(2) +
                    ' deposit (' + opt.apr + '% interest).'
                );
                $('.product__financing').fadeIn();
            }

            $('select', $finTable).append($('<option>', {
                value: i,
                text: opt.p_name
            }));

            ++i;
        }
    }

    $('select', $finTable).on('change', function() {
        var opt = tdDetails[$(this).prop('selectedIndex')],
            cur = '&pound;';

        $('#cost_of_goods', $finTable).html(cur + parseFloat(opt.goods_val).toFixed(2));
        $('#deposit_percentage', $finTable).html(opt.d_pc + '%');
        $('#deposit_amount', $finTable).html(cur + parseFloat(opt.d_amount).toFixed(2));
        $('#credit_amount', $finTable).html(cur + parseFloat(opt.l_amount).toFixed(2));
        $('#finance_term', $finTable).html(opt.term + ' months');
        $('#apr', $finTable).html(opt.apr + '%');
        $('#cost_per_month', $finTable).html(cur + parseFloat(opt.m_inst).toFixed(2));
        $('#monthly_repayment', $finTable).html(cur + parseFloat(opt.l_repay).toFixed(2));
        $('#total', $finTable).html(cur + parseFloat(opt.total).toFixed(2));
    }).trigger('change');
}
$('document').ready(updateFinancing);

