var SELECTREPLACE = {
    classes: {
        focused:  'multi-select-replace--focused',
        disabled: 'multi-select-replace--disabled',
        // single:   'multi-select-replace--single',
        multiple: 'multi-select-replace--multiple',
        active: 'multi-select-replace--active',
        search:   'multi-select-replace--search',
        listitem: 'multi-select-replace__list-item',
        selected: 'multi-select-replace__list-item--selected',
        current:  'multi-select-replace__list-item--current',
        nobbled:  'multi-select-replace__list-item--disabled',
        hidden:   'multi-select-replace__list-item--hidden',
        image:    'multi-select-replace__list-item--image',
        overview: 'multi-select-replace__overview',
        mobileOverview: 'multi-select-replace__mobile-overview',
        action:   'multi-select-replace__action',
        actions:  'multi-select-replace__actions',
        icon:     'multi-select-replace__icon',
        back:     'multi-select-replace__back',
        count:    'multi-select-replace__count'
    },

    keys: {
        up:     38,
        dn:     40,
        pgup:   33,
        pgdn:   34,
        escape: 27,
        space:  32,
        enter:  13,
    },

    page: 5,

    $focused: false,

    svgIconPath: AndrewMartin.svgSymbolsFile,
    svgIcon: {
        back: 'icon-select',
        dropdown: 'icon-select',
        selected: 'icon-tick',
        clear: 'icon-close'
    }
};

// Styled select elements
$.fn.selectReplace = function() {
    $(this).filter(':not(.multi-select-replaced)').each(function() {
        var $select = $(this);

        var $form     = $select.closest('form');
        var $label    = $select.prev('label');
        var $extra    = $select.next('.filterable__extra-content');
        var $wrapper  = $select.wrap('<div class="multi-select-replace"></div>').parent();
        var $input    = $('<input class="multi-select-replace__search">').appendTo($wrapper);
        var $drop     = $('<div class="multi-select-replace__drop"></div>').appendTo($wrapper);
        var $up       = $('<a href="#" class="multi-select-replace__elevator-button multi-select-replace__elevator-button--up"></a>').appendTo($drop);
        var $down     = $('<a href="#" class="multi-select-replace__elevator-button multi-select-replace__elevator-button--down"></a>').appendTo($drop);

        var $list = $('<ul class="multi-select-replace__list"></ul>');

        $select.find('option').each(function(ndx) {
            var $option = $(this);

            var $li = $('<li class="' + SELECTREPLACE.classes.listitem + '" data-value="' + $option.val() + '"><a href="#0">' + $option.text() + '</a></li>');

            if ($option.is('[data-hidden]')) {
                $li.addClass(SELECTREPLACE.classes.hidden);
            }

            if ($option.is(':disabled')) {
                $li.addClass(SELECTREPLACE.classes.nobbled);
            }

            if ($option.attr('data-image') !== undefined) {
                $('<img src="' + $option.attr('data-image') + '" />').appendTo($li);
                $li.addClass(SELECTREPLACE.classes.image);
            }

            $li.appendTo($list);

            var $icon = $('<span class="multi-select-replace__status"><svg class="' + SELECTREPLACE.svgIcon.selected + '">\
                <use xlink:href="' + SELECTREPLACE.svgIconPath + '#' + SELECTREPLACE.svgIcon.selected + '" />\
            </svg></span>').appendTo($li);


            if ($option.text().toLowerCase() == 'multicoloured') {
                var $swatch = $('<div class="multi-select-replace__swatch multi-select-replace__swatch--multicoloured"></div>').prependTo($li);
            } else if ($option.attr('color') !== '') {
                var $swatch = $('<div class="multi-select-replace__swatch" style="color: ' + $option.attr('color') + ';"></div>').prependTo($li);
            }
        });

        var all = '<span class="' + SELECTREPLACE.classes.action + ' ' + SELECTREPLACE.classes.action + '--all">Select all</span>';
        var clear = '<span class="' + SELECTREPLACE.classes.action + ' ' + SELECTREPLACE.classes.action + '--clear">Clear all</span>';

        var $overviewMobile = $('<div class="' + SELECTREPLACE.classes.listitem + ' ' + SELECTREPLACE.classes.listitem + '--overview ' + SELECTREPLACE.classes.listitem + '--overview-1" data-value="all"><div class="' + SELECTREPLACE.classes.actions + '">' + all + clear + '</div></div>');
        $overviewMobile.appendTo($drop);

        var $overview = $('<div class="' + SELECTREPLACE.classes.listitem + ' ' + SELECTREPLACE.classes.listitem + '--overview ' + SELECTREPLACE.classes.listitem + '--overview-2" data-value="all"><div class="' + SELECTREPLACE.classes.actions + '">' + all + clear + '</div></div>');
        $overview.appendTo($drop);

        $list.appendTo($drop);

        if ($extra) {
            $extra.appendTo($drop);
        }

        var $options = $list.find('li');

        $wrapper.addClass(SELECTREPLACE.classes.multiple);

        if ($select.prop('id')) {
            $select.parent().prop('id', $select.prop('id') + '_replace');
        }

        $select.addClass('multi-select-replaced');

        $label.on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            deactivateFilterRanges($('.js-filter-ranges'))
            if (SELECTREPLACE.$focused) {
                if (SELECTREPLACE.$focused !== $wrapper) {
                    // clicked a different select
                    SELECTREPLACE.$focused.trigger('_blur');

                    $wrapper.trigger('_focus');
                } else {
                    $wrapper.trigger('_blur');
                }
            } else {
                $wrapper.trigger('_focus');
            }
        });

        $list.on('click', '.' + SELECTREPLACE.classes.listitem, function(e) {
            e.preventDefault();

            var $item = $(this);

            // if ($item.data('value') != 'all') {
                $item.toggleClass(SELECTREPLACE.classes.selected);

                var eventName = $item.hasClass(SELECTREPLACE.classes.selected) ? '_selected' : '_unselected';

                $select.trigger(eventName, this);
            // }

            $wrapper.trigger('_change');
        });

        $wrapper.on('click', '.' + SELECTREPLACE.classes.back, function(e) {
            $wrapper.trigger('_blur');
        });

        $wrapper.on('click', '.' + SELECTREPLACE.classes.actions, function(e) {
            var $items = $list.find('.' + SELECTREPLACE.classes.listitem)//.not($item);

            var showAll;

            $wrapper.find('.' + SELECTREPLACE.classes.actions).each(function() {
                var $item = $(this).parent();
                $item.data('active', !$item.data('active'));

                showAll = $item.data('active');

                $overviewMobile.attr('data-all', showAll);
                $overview.attr('data-all', showAll);
            });

            $items.each(function() {
                var $item = $(this);
                var eventName = '_unselected';
                $item.removeClass(SELECTREPLACE.classes.selected);

                if (showAll) {
                    $item.addClass(SELECTREPLACE.classes.selected);
                    eventName = '_selected';
                }

                $select.trigger(eventName, this);
            });

            $wrapper.trigger('_change');
        });

        $wrapper.on('_focus', function(e) {
            SELECTREPLACE.$focused = $wrapper;
            $wrapper.addClass(SELECTREPLACE.classes.focused);
            $wrapper.parents('.filterable__dimension').addClass('filterable__dimension--active');
            $wrapper.parents('.filterable__content').addClass('filterable__content--active');
            countListItems(e)
        });

        // Count number of li's

        function countListItems(e) {

            var list = e.target.querySelector('.multi-select-replace__list')
            var drop = list.parentElement

            if ( list.childElementCount > 17) {
                list.classList.add('multi-select-replace__list--large')
                drop.classList.add('multi-select-replace__drop--large')

            } else if ( list.childElementCount >= 7) {
                list.classList.add('multi-select-replace__list--medium')

            } else if ( list.childElementCount <= 6) {
                list.classList.add('multi-select-replace__list--small')
            }

            var centrePoint = (window.innerWidth / 2);

            if (drop.getBoundingClientRect().x > centrePoint) {
                drop.classList.add('multi-select-replace__drop--right-align')
            }
        }

        $wrapper.on('_blur', function() {
            SELECTREPLACE.$focused = false;
            $wrapper.removeClass(SELECTREPLACE.classes.focused);
            $wrapper.parents('.filterable__dimension').removeClass('filterable__dimension--active');
            $wrapper.parents('.filterable__content').removeClass('filterable__content--active');
        });

        $wrapper.on('_change', function(e, initial) {
            var $selected = $options.filter('.' + SELECTREPLACE.classes.selected).not('[data-value="all"]');
            var $items = $options.not('[data-value="all"]');

            var selectValue = '';
            if ($select.data('blank') !== undefined) {
                selectValue = $select.data('blank');
            }

            var html = '';

            if ($selected.length) {
                $selected.each(function(i) {
                    html += $(this).find('a').html();

                    if (i < $selected.length - 1) {
                        html += ', ';
                    }
                });
                selectValue = $selected.length + ' selected';
                $wrapper.addClass(SELECTREPLACE.classes.active);
                $wrapper.parent().addClass('filterable__dimension--has-selection');
                $('.js-clear-filters').show();
            } else {
                html = 'None selected';
                $wrapper.removeClass(SELECTREPLACE.classes.active);
                $wrapper.parent().removeClass('filterable__dimension--has-selection');
                $('.js-clear-filters').hide();
            }

            $wrapper.find('.' + SELECTREPLACE.classes.count).html($selected.length);

            if ($selected.length > 0) {
                $label.find('.js-filterable__count').html('(' + $selected.length + ')');
            } else {
                $label.find('.js-filterable__count').html('');
            }

            if ($selected.length == 0) {
                $overview.data('active', false);
                $overview.attr('data-all', false);
                $overviewMobile.attr('data-all', false);
            } else {
                $overview.data('active', true);
                $overview.attr('data-all', true);
                $overviewMobile.attr('data-all', true);
            }

            // $value.html(selectValue);
            $wrapper.find('.' + SELECTREPLACE.classes.overview).html(html).attr('data-is-empty', $selected.length == 0);

            $select.find('option').each(function(ndx) {
                var $option = $(this);

                if ($options.eq(ndx).hasClass(SELECTREPLACE.classes.selected)) {
                    $option.prop('selected', true);
                } else {
                    $option.prop('selected', null);
                }
            });

            if (!initial) {
                $select.trigger('change', initial);
            }
        });

        $(document).on('keydown', function(e) {
            if (!SELECTREPLACE.$focused) {
                return;
            }

            if (e.which == SELECTREPLACE.keys.escape) {
                $wrapper.trigger('_blur');
            }
        });

        $select.on('_change', function() {
            $select.find('option').each(function(ndx) {
                var $option = $(this);

                if ($option.prop('selected')) {
                    $li = $options.eq(ndx);
                    $li.addClass(SELECTREPLACE.classes.selected);
                }
            });
        });

        $form.on('reset', function(e) {
            $options.removeClass(SELECTREPLACE.classes.selected);
            $wrapper.trigger('_change');
        });

        $select.trigger('_change', true);
    });
};

$(document).on('click', function(e) {
    if (SELECTREPLACE.$focused) {
        if (!$.contains(SELECTREPLACE.$focused.get(0), e.target)) {
            // clicked outside of the current select
            SELECTREPLACE.$focused.trigger('_blur');
        }
    }
});

$('select[multiple]').selectReplace();
