
$(function() {
    $('.design-directory__filters').find('input, select').on('change', function() {
        $(this).parents('form').submit();
    });

    $(document).on('click', '.js-design-directory-load-more', function(event) {
        event.preventDefault();
        $('.load-more').addClass('load-more--loading');
        $.ajax(
            $(this).attr('href')
        ).done(function(payload) {
            newItems = $(payload).find('.design-directory__list .grid > *').css('display', 'none').addClass('js-should-fade-in');
            loadMore = $(payload).find('.design-directory__load-more');
            $('.design-directory__list .grid').append(newItems);
            $('.js-should-fade-in').fadeIn().removeClass('js-should-fade-in');
            $('.design-directory__load-more').replaceWith(loadMore);
        });
    });

});
